export const New = () => {
  return (
    <div className="new">
      <p className="new__title">What news?</p>
      <p className="new__description">Yeni oyunculara BONUS 37.500 ₺!</p>
      <p className="new__description">
        • 1.000.000 ₺ çekilişine katılım ücretsiz!
      </p>
      <p className="new__description">
        • 300 FS (serbest döndürme) – kaydırma tekerleği
      </p>
      <p className="new__description">
        • Çeşitli hizmetler için oyunculara ödeme yapmak üzere bonus puanların
        tahakkuku.
      </p>
      <p className="new__description">
        • İlk para yatırmada %150 bonus – kayıttan sonraki 30 gün boyunca
        geçerlidir.
      </p>
    </div>
  );
};
